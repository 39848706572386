interface IVGSCollect {
  serviceType: string;
  vaultId: string;
  environment: string;
  version: string;
  url: string;
}
//https://tntkd2oedp0.sandbox.verygoodproxy.com/api/transferp2p/token

export const VGSCollect: IVGSCollect[] = [
  {
    serviceType: 'P2P',
    vaultId: 'tntkd2oedp0',
    environment: 'sandbox',
    version: '2.19.0',
    url: '/api/transferp2p/token',
  },
];
export const environment = {
  production: false,
  apiUrl: 'https://localhost:44398',
  p2pURL: 'https://localhost:4200',
  oplatamdURL: 'https://localhost:44310',
};
