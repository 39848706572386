import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RequestFormComponent } from './components/request-form/request-form.component';
import { CallbackPageFROMComponent } from './components/callback-page-from/callback-page-from.component';
import { ResultPageComponent } from './components/result-page/result-page.component';
import { ExceptionPageComponent } from './components/exception-page/exception-page.component';

const routes: Routes = [
  {
    path: '',
    component: RequestFormComponent,
    data: {
      title: 'p2p-plugin',
    },
  },
  {
    path: 'callback-page-from/:paymentkey/:checkoutkey/:p2psessionkey',
    component: CallbackPageFROMComponent,
  },
  {
    path: 'result/:lang/:paymentkey/:checkoutkey/:p2psessionkey',
    component: ResultPageComponent,
  },
  {
    path: 'exception/:lang',
    component: ExceptionPageComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
