import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from '../../environments/environment';
import { FormControl, Validators } from '@angular/forms';

export const Currencies = [
  {
    Code: 'MDL',
    Num: 498,
  },
  // {
  //   Code: 'EUR',
  //   Num: 978,
  // },
  // {
  //   Code: 'USD',
  //   Num: 840,
  // },
];

export const CurrencyLimits: ICurrencyLimit[] = [
  {
    currencyId: 498,
    min: 10000,
    max: 2000000,
  },
  {
    currencyId: 840,
    min: 2000,
    max: 200000,
  },
  {
    currencyId: 978,
    min: 2000,
    max: 200000,
  },
];

export interface ICurrencyLimit {
  currencyId: number;
  min: number;
  max: number;
}

export class CurrencyLimit implements ICurrencyLimit {
  currencyId: number;
  min: number;
  max: number;

  constructor(currency) {
    this.currencyId = currency.currencyId;
    this.min = currency.min;
    this.max = currency.max;
  }
}

export interface ICurrency {
  Code: string;
  Num: number;
}

export class Currency implements ICurrency {
  Code: string;
  Num: number;

  constructor(currency) {
    this.Code = currency.code;
    this.Num = currency.num;
  }
}

export enum IValidationType {
  required = 'required',
  minlength = 'minlength',
  maxlength = 'maxlength',
  min = 'min',
  max = 'max',
  regexp = 'regexp',
}

interface IValidation {
  Type: IValidationType;
  Error: string;
}

export class Validation implements IValidation {
  Type: IValidationType;
  Error: string;

  constructor(coreModel: any) {
    this.Type = coreModel.type;
    this.Error = coreModel.error;
  }
}

interface IFormFields {
  ID: string;
  Label: string;
  Name: string;
  Placeholder: string;
  Required: boolean;
  Type: string;
  Validation: IValidation[];
  Value: string;
}

export class FormField implements IFormFields {
  ID: string;
  Label: string;
  Name: string;
  Placeholder: string;
  Required: boolean;
  Readonly?: boolean;
  AutoComplete?: string;
  Type: string;
  Validation: Validation[] = [];
  Value: string;
  MaxLength?: number = null;
  HasFormControlValidation: boolean;
  FormControl?: FormControl;

  mapError(ctrlerrors) {
    //console.log(ctrlerrors);
    const validation = this.Validation.find(
      (x) => !!ctrlerrors && x.Type === Object.keys(ctrlerrors)[0]
    );
    return validation?.Error ?? '';
  }

  constructor(field: any) {
    this.ID = field.id;
    this.Label = field.label;
    this.Name = field.name;
    this.Placeholder = field.placeholder;
    this.AutoComplete = field.autocomplete;
    this.Required = field.required;
    this.Readonly = field?.readonly;
    this.Type = field.type;
    this.Value = field.value;

    if (field.validation.length > 0) {
      field.validation.forEach((element) => {
        this.Validation.push(new Validation(element));
      });
    }
    this.Value = field.value;
    this.HasFormControlValidation = !!field?.validators;
    this.FormControl = new FormControl(this.Value);
    if (!!this.HasFormControlValidation) {
      const validators = [
        ...(field?.validators?.required ? [Validators.required] : []),
        ...(field?.validators?.min
          ? [Validators.min(field.validators.min)]
          : []),
        ...(field?.validators?.max
          ? [Validators.max(field.validators.max)]
          : []),
        ...(field?.validators?.minlength
          ? [Validators.minLength(field.validators.minlength)]
          : []),
        ...(field?.validators?.maxlength
          ? [Validators.maxLength(field.validators.maxlength)]
          : []),
        ...(field?.validators?.pattern
          ? [Validators.pattern(field.validators.pattern)]
          : []),
      ];
      this.MaxLength = field?.validators?.maxlength;
      //console.log('validators: ', validators);
      this.FormControl.setValidators(validators);
    }
  }
}

export class SanitizedSRC {
  IFRAMESenderBlock: SafeResourceUrl;
  IFRAMEReceiverBlockCardNr: SafeResourceUrl;
  IFRAMECallBackPageTO: SafeResourceUrl;
  LINKOplataMdTerms: SafeResourceUrl;
  constructor(language: string, private sanitizer: DomSanitizer) {
    this.IFRAMESenderBlock = this.sanitizer.bypassSecurityTrustResourceUrl(
      `${
        environment.pspURL
      }/p2p/sender-block/${language}?cachebust=${Math.random().toFixed(8)}`
    );
    this.IFRAMEReceiverBlockCardNr =
      this.sanitizer.bypassSecurityTrustResourceUrl(
        `${
          environment.pspURL
        }/p2p/receiver-cardnr/${language}?cachebust=${Math.random().toFixed(8)}`
      );
    this.IFRAMECallBackPageTO = this.sanitizer.bypassSecurityTrustResourceUrl(
      `${environment.pspURL}/callback-page-to?cachebust=${Math.random().toFixed(
        8
      )}`
    );
    this.LINKOplataMdTerms = this.sanitizer.bypassSecurityTrustResourceUrl(
      `${environment.oplatamdURL}/terms`
    );
  }
}
