export enum PM_ACTIONS {
  //P2P
  P2P_START_IN = 'P2P-START-IN',
  P2P_INPROGRESS = 'P2P-INPROGRESS',
  P2P_NEW = 'P2P-NEW',
  P2P_PRINT = 'P2P-PRINT',
  P2P_EXCEPTION = 'P2P-EXCEPTION',
  P2P_EXCEPTION_RESPONSE = 'P2P-EXCEPTION-RESPONSE',
  P2P_WAITING_3DS = 'P2P-WAITING-3DS',
  //PSP
  PSP_VGS_LOADED = 'PSP-VGS-LOADED',
  PSP_READY = 'PSP-READY',
  PSP_VALIDATE_IN = 'PSP-VALIDATE-IN',
  PSP_VALIDATE_OUT = 'PSP-VALIDATE-OUT',
  //PSP_PREPARE_CHECKOUT = 'PSP-PREPARE-CHECKOUT',
  PSP_INIT_FIELDS = 'PSP-INIT-FIELDS',
  PSP_SUBMIT = 'PSP-SUBMIT',
  PSP_TOKENIZED = 'PSP-TOKENIZED',
  PSP_CONFIRM = 'PSP-CONFIRM',
  PSP_PREPARE_3DS = 'PSP-PREPARE-3DS',
  PSP_EXCEPTION = 'PSP-EXCEPTION',
  //reCAPTCHA v3
  P2P_RECAPTCHA_VERIFY = 'P2P-RECAPTCHA-VERIFY',
  P2P_RECAPTCHA_RESPONSE = 'P2P-RECAPTCHA-RESPONSE',
}

export enum ERR_SERVER {
  FEE_EXCEPTION = 'REQFORM.ERR.SERVER.FEE_EXCEPTION',
  RECAPTCHA_EXCEPTION = 'REQFORM.ERR.SERVER.RECAPTCHA_EXCEPTION',
}

export interface IServerError {
  field: string;
  error: ERR_SERVER;
}

export const Langs: string[] = ['ro', 'ru'];

export function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

export const FormControlKeyPress = (event, pattern): boolean => {
  const el = event.target;
  const val = event.target.value;
  const inp = String.fromCharCode(event.keyCode);
  if (val.length < el.maxLength && pattern.test(inp)) {
    return true;
  } else {
    event.preventDefault();
    return false;
  }
};

export const FormControlPaste = (event: ClipboardEvent, pattern): boolean => {
  const val = event.clipboardData?.getData('text');
  if (pattern.test(val)) {
    return true;
  } else {
    event.preventDefault();
    return false;
  }
};

export const AmountKeyPress = (event): boolean => {
  //console.log(event);
  //const el = event.target;
  const val = event.target.value;
  const inp = String.fromCharCode(event.keyCode);
  if (/^\d*(?:[\.\,]\d{2})?$/.test(val + inp)) {
    return true;
  } else {
    if (/^\d*(?:[\.\,]\d{0,2})?$/.test(val + inp)) {
      return true;
    } else {
      if (/^\d*(?:[\.\,]\d{1,2})?$/.test(val + inp)) {
        return true;
      } else if (/^(?:[\.\,]\d{0,2})?$/.test(val + inp)) {
        return true;
      } else if (/^\d*(?:[\.\,]\d{2})?$/.test(val) && /^\d$/.test(inp)) {
        return true;
      }
    }
    event.preventDefault();
    return false;
  }
};

// export const AmountOnInput = (event) => {
//   console.log(event);
//   const el = event.target;
//   let val = event.target.value;
//   //  const inp = String.fromCharCode(event.keyCode);
//   if (
//     !/^\d*(?:[\.\,]\d{2})?$/.test(val) &&
//     nondigit_position(val) !== undefined
//   ) {
//     val = remove_character(val, nondigit_position(val));
//   }
//   el.value = val;
// };

// function nondigit_position(str) {
//   let pos;
//   for (let position = 0; position < str.length; position++) {
//     if (
//       str.charAt(position) !== '0' &&
//       str.charAt(position) !== '1' &&
//       str.charAt(position) !== '2' &&
//       str.charAt(position) !== '3' &&
//       str.charAt(position) !== '4' &&
//       str.charAt(position) !== '5' &&
//       str.charAt(position) !== '6' &&
//       str.charAt(position) !== '7' &&
//       str.charAt(position) !== '8' &&
//       str.charAt(position) !== '9' &&
//       str.charAt(position) !== ',' &&
//       str.charAt(position) !== '.'
//     ) {
//       pos = position;
//     }
//   }
//   return pos;
// }

// function remove_character(str, char_pos) {
//   const part1 = str.substring(0, char_pos);
//   const part2 = str.substring(char_pos + 1, str.length);
//   return part1 + part2;
// }

export function AmountGetString(pAmount): string {
  return evenRound(parseFloat((pAmount / 100).toString())).toFixed(2);
}
export function AmountGetAsLong(amountStr) {
  amountStr = ['', ',', '.'].includes(amountStr) ? '0.00' : amountStr;
  amountStr = amountStr.replace(',', '.');
  var amountRaw = parseFloat(amountStr).toFixed(2);
  return parseInt(evenRound(Number(amountRaw) * 100).toString());
}
export function FormatAmountString(pAmount): string {
  const intl = Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return intl.format(pAmount);
}
function evenRound(num, decimalPlaces = 2) {
  var d = decimalPlaces || 0;
  var m = Math.pow(10, d);
  var n = +(d ? num * m : num).toFixed(8); // Avoid rounding errors
  var i = Math.floor(n),
    f = n - i;
  var e = 1e-8; // Allow for rounding errors in f
  var r = f > 0.5 - e && f < 0.5 + e ? (i % 2 == 0 ? i : i + 1) : Math.round(n);
  return d ? r / m : r;
}

export const FormatDateTime = (culture: string, date: Date): string => {
  return `${date
    .toLocaleString(culture, {
      month: 'short',
    })
    .slice(0, 3)} ${date.toLocaleString(culture, {
    day: '2-digit',
  })} ${date.toLocaleString(culture, {
    year: 'numeric',
  })} ${date.toLocaleString(culture, {
    hour12: false,
    hour: '2-digit',
    minute: '2-digit',
  })}`;
};
