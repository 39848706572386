import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { CultureSharedService } from '../_services/culture.shared.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  currentLang: string
  constructor(
    private cultureSharedService: CultureSharedService,
    private translateService: TranslateService
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // if (this.skipHandling(request)) {
    //   return next.handle(request);
    // }
    request = request.clone({
      url: `${environment.apiUrl}/${request.url}`,
      setHeaders: {
        'Accept-Language': this.getLanguage() ?? 'ru',
      },
    });
    console.log('p2p this.translateService.currentLang', this.translateService.currentLang)
    return next.handle(request);
  }
  private skipHandling(request: HttpRequest<any>) {
    return request.url.includes('/content/gettranslates/');
  }

  private getLanguage(): string {
    this.cultureSharedService.currentLang$.subscribe({
      next: (lang) => {
        if (!!lang) {
          this.currentLang = lang
        }
      }
    })
    return this.currentLang
  }
}
