export function blobToBase64(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64String = reader.result;
      resolve(base64String);
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
}

export function DetectMimeType(base64String) {
  const decodedData = atob(base64String);
  const arrayBuffer = new ArrayBuffer(decodedData.length);
  const uint8Array = new Uint8Array(arrayBuffer);

  for (let i = 0; i < decodedData.length; i++) {
    uint8Array[i] = decodedData.charCodeAt(i);
  }

  const imageFormat = getImageFormat(uint8Array);
  console.log('imageFormat: ', imageFormat);
  return imageFormat;
}

const ImageHeaderData = {
  PNG: [0x89],
  JPEG: [0xff],
  PDF: [0x25],
  GIF: [0x47],
  TIFF_01: [0x49],
  TIFF_02: [0x4d],
};

const ImageFormat = {
  Unknown: 'Unknown',
  PNG: 'image/png',
  JPEG: 'image/jpeg',
  GIF: 'image/gif',
  TIFF: 'image/tiff',
  PDF: 'application/pdf',
};

function getImageFormat(data) {
  const buffer = new Uint8Array(data.slice(0, 1));

  if (compareBuffers(buffer, ImageHeaderData.PNG)) {
    return ImageFormat.PNG;
  } else if (compareBuffers(buffer, ImageHeaderData.JPEG)) {
    return ImageFormat.JPEG;
  } else if (compareBuffers(buffer, ImageHeaderData.GIF)) {
    return ImageFormat.GIF;
  } else if (compareBuffers(buffer, ImageHeaderData.PDF)) {
    return ImageFormat.PDF;
  } else if (
    compareBuffers(buffer, ImageHeaderData.TIFF_01) ||
    compareBuffers(buffer, ImageHeaderData.TIFF_02)
  ) {
    return ImageFormat.TIFF;
  } else {
    return ImageFormat.Unknown;
  }
}

function compareBuffers(buffer1, buffer2) {
  if (buffer1.length !== buffer2.length) {
    return false;
  }

  for (let i = 0; i < buffer1.length; i++) {
    if (buffer1[i] !== buffer2[i]) {
      return false;
    }
  }

  return true;
}
