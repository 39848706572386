import { CultureSharedService } from './../_services/culture.shared.service';
import {
  AmountGetAsLong,
  AmountGetString,
  FormatAmountString,
  FormatDateTime,
} from './common.model';

export interface ISaveRequestIN {
  SenderCardType: string;
  SenderCardBin: string;
  SenderCardLast4: string;
  SenderCardHolderName: string;
  SenderAddress: string;
  SenderLocation: string;
  ReceiverCardType: string;
  ReceiverCardBin: string;
  ReceiverCardLast4: string;
  ReceiverCardHolderName: string;
  CurrencyID: number;
  Amount: number;
  AmountTotal?: number;
  Totals?: number;
}

export class SaveRequest implements ISaveRequestIN {
  SenderCardType: string;
  SenderCardBin: string;
  SenderCardLast4: string;
  SenderCardHolderName: string;
  SenderAddress: string;
  SenderLocation: string;
  ReceiverCardType: string;
  ReceiverCardBin: string;
  ReceiverCardLast4: string;
  ReceiverCardHolderName: string;
  CurrencyID: number;
  Amount: number;
  AmountTotal: number;

  constructor(formData: ISaveRequestIN) {
    this.SenderCardType = formData.SenderCardType;
    this.SenderCardBin = formData.SenderCardBin;
    this.SenderCardLast4 = formData.SenderCardLast4;
    this.SenderCardHolderName = formData.SenderCardHolderName;
    this.SenderAddress = formData.SenderAddress;
    this.SenderLocation = formData.SenderLocation;
    this.ReceiverCardType = formData.ReceiverCardType;
    this.ReceiverCardBin = formData.ReceiverCardBin;
    this.ReceiverCardLast4 = formData.ReceiverCardLast4;
    this.ReceiverCardHolderName = formData.ReceiverCardHolderName;
    this.CurrencyID = formData.CurrencyID;
    this.Amount = AmountGetAsLong(formData.Amount);
    this.AmountTotal = AmountGetAsLong(formData.Totals);
  }
}

interface ISaveRequestTokenBox {
  formFieldCode: string;
  tokenBoxKey: string;
}

export interface ISaveRequestOUT {
  transferOrderKey: string;
  associatedTokenBoxKeys: ISaveRequestTokenBox[];
}

export interface IRecalculateAmountIN {
  amount: number;
  senderCardBin: string;
  receiverCardBin: string;
  currencyId: number;
}

export interface IRecalculateAmountOUT {
  comission: number;
  amountTotal: number;
}

export interface IPSPSubmitOUT {
  SenderCardExpiryDate: string;
}

export interface IInitPaymentIN {
  transferOrderKey: string;
}

export class InitPaymentIN implements IInitPaymentIN {
  transferOrderKey: string;
  backUrlTemplate: string;
  browserJson: string;

  constructor(IN: IInitPaymentIN) {
    this.transferOrderKey = IN.transferOrderKey;
    this.backUrlTemplate = `https://${window.location.host}/callback-page-from/{transferPayment.Key}/{checkout.Key}/{authToken}`;
    this.browserJson = JSON.stringify({
      userAgent: navigator.userAgent,
      colorDepth: screen.colorDepth,
      screenHeight: screen.height,
      screenWidth: screen.width,
      javaEnabled: !!navigator?.javaEnabled(),
      browserLanguage: navigator.language,
      browserTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      browserTimeZoneOffset: new Date().getTimezoneOffset(),
      browserAcceptHeader: '',
      browserIpAddress: '',
      javascriptEnabled: true,
    });
  }
}

export interface IInitPaymentOUT {
  transferPaymentKey: string;
  checkoutKey: string;
  p2psessionKey: string;
}

export interface IGetDetailsIN {
  paymentKey: string;
  checkoutKey: string;
  p2psessionKey: string;
  currentLang?: string;
}

interface IFile {
  fileContents: string;
  blob: Blob;
}
export interface IGetDetailsPdfOUT {
  file: IFile;
}

export interface IGetDetailsOUT {
  requestId: string;
  paymentId: string;
  operationType: string;
  serviceProvider: string;
  senderCardholderName: string;
  senderCard: string;
  receiverCardholderName: string;
  receiverCard: string;
  accountingOperationId: string;
  rrn: string;
  approvalCode: string;
  paidDate: string;
  amountTransfer: number;
  commission: number;
  amountTotal: number;
  currency: string;
  status: number;
  statusDesc: string;
  error: string;
}

export enum Status {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  INPROGRESS = 'INPROGRESS',
  UNKNOWN = 'UNKNOWN',
}

const CSS_Status_MAP = [
  {
    status: Status.SUCCESS,
    color: 'green',
  },
  {
    status: Status.FAILED,
    color: 'red',
  },
  {
    status: Status.INPROGRESS,
    color: 'gold',
  },
  {
    status: Status.UNKNOWN,
    color: 'red',
  },
];

const getStatus = (val: number): Status => {
  val = isNaN(val) ? -1 : val;
  return val === 0
    ? Status.INPROGRESS
    : val === 1
      ? Status.SUCCESS
      : val === 2
        ? Status.FAILED
        : Status.UNKNOWN;
};

export class GetDetailsOUT {
  RES_Status_TRANSLATE: string;
  CSS_Status_H4: string;
  SRC_Status_IMG: string;
  Status: Status;
  ErrorCode: string;
  OperationType: string;
  ServiceIssuer: string;
  SenderName: string;
  SenderCardNr: string;
  ReceiverName: string;
  ReceiverCardNr: string;
  OperationID: string;
  CodeAuth: string;
  RRN: string;
  TransferDate: string;
  TransferAmount: string;
  Fee: string;
  Total: string;

  constructor(core?: IGetDetailsOUT, currentLang?: string) {
    if (!!core) {
      console.log('IGetDetailsOUT: ', core);
      this.Status = getStatus(core.status);
      this.CSS_Status_H4 = `text-${CSS_Status_MAP.find((x) => x.status === this.Status).color
        }`;
      this.RES_Status_TRANSLATE = `RESULTPAGE.H4.OPERATION.${this.Status}`;
      this.SRC_Status_IMG = `RESULTPAGE.IMG.${this.Status}`;
      this.ErrorCode = core.error;
      this.OperationType = core.operationType;
      this.ServiceIssuer = core.serviceProvider;
      this.SenderName = core.senderCardholderName;
      this.SenderCardNr = core.senderCard;
      this.ReceiverName = core.receiverCardholderName;
      this.ReceiverCardNr = core.receiverCard;
      this.OperationID = core.paymentId;
      this.RRN = core.rrn;
      this.CodeAuth = core.approvalCode;
      this.TransferDate = core.paidDate
        ? FormatDateTime(
          currentLang,
          new Date(core.paidDate)
        )
        : '';

      this.TransferAmount = `${FormatAmountString(
        AmountGetString(core.amountTransfer)
      )} ${core.currency}`;
      this.Fee = `${FormatAmountString(AmountGetString(core.commission))} ${core.currency
        }`;
      this.Total = `${FormatAmountString(AmountGetString(core.amountTotal))} ${core.currency
        }`;
    }
  }
}

export class OplataPrintField {
  Key: string;
  Name: string;
  Value: string;
  constructor(IN) { }
}

export class GetPrintOUT {
  Fields: OplataPrintField[];
}

export interface IGetExceptionIN {
  exceptionCode: string;
  exceptionMessage: string;
}

export interface IGetExceptionOUT {
  exceptionCode: string;
  exceptionMessage: string;
}
export class GetExceptionOUT {
  SRC_IMG: string;
  ErrorCode: string;
  ErrorMessage: string;

  constructor(core?: IGetExceptionOUT) {
    this.ErrorCode = core.exceptionCode;
    // this.ErrorMessage = `EXCEPTIONPAGE.H4.MESSAGE.${core.exceptionCode}`;
    this.ErrorMessage = core.exceptionMessage;
    this.SRC_IMG = `EXCEPTIONPAGE.IMG`;
  }
}
