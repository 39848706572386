import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable, catchError, from, map, switchMap, throwError } from 'rxjs';
import {
  GetDetailsOUT,
  GetExceptionOUT,
  GetPrintOUT,
  IGetDetailsIN,
  IGetDetailsOUT,
  IGetDetailsPdfOUT,
  IGetExceptionIN,
  IInitPaymentIN,
  IInitPaymentOUT,
  IRecalculateAmountIN,
  IRecalculateAmountOUT,
  ISaveRequestIN,
  ISaveRequestOUT,
  InitPaymentIN,
  Status,
} from '../_models/p2p.service.model';
import {
  Currencies,
  Currency,
  CurrencyLimit,
  CurrencyLimits,
  FormField,
  ICurrencyLimit,
} from '../_models/request-form.model';
import HttpClientUtils from '../_helpers/HttpUtils';
import { TranslateService } from '@ngx-translate/core';
import { DetectMimeType } from '../_helpers/BlobUtils';

const mockFields = [
  {
    id: 'SenderCardHolderName',
    value: null,
    name: 'SenderCardHolderName',
    type: 'text',
    required: true,
    placeholder: 'REQFORM.PLH.SENDERCARDHOLDERNAME',
    autocomplete: 'cc-name',
    validation: [
      {
        type: 'required',
        error: 'REQFORM.ERR.REQ.SENDERCARDHOLDERNAME',
      },
      {
        type: 'minlength',
        error: 'REQFORM.ERR.REGEXP.SENDERCARDHOLDERNAME',
      },
      {
        type: 'maxlength',
        error: 'REQFORM.ERR.REGEXP.SENDERCARDHOLDERNAME',
      },
      {
        type: 'pattern',
        error: 'REQFORM.ERR.REGEXP.SENDERCARDHOLDERNAME',
      },
    ],
    validators: {
      required: true,
      minlength: 7,
      maxlength: 100,
      pattern: '[a-zA-Z]+(?:[-][a-zA-Z]+)* [a-zA-Z]+(?:[-][a-zA-Z]+)*$',
    },
    label: 'REQFORM.LBL.SENDERCARDHOLDERNAME',
  },
  {
    id: 'SenderAddress',
    value: null,
    name: 'SenderAddress',
    required: true,
    type: 'text',
    placeholder: 'REQFORM.PLH.SENDERADDRESS',
    autocomplete: 'street-address',
    validation: [
      {
        type: 'required',
        error: 'REQFORM.ERR.REQ.SENDERADDRESS',
      },
      {
        type: 'minlength',
        error: 'REQFORM.ERR.REGEXP.SENDERADDRESS',
      },
      {
        type: 'maxlength',
        error: 'REQFORM.ERR.REGEXP.SENDERADDRESS',
      },
    ],
    validators: {
      required: true,
      minlength: 3,
      maxlength: 100,
    },
    label: 'REQFORM.LBL.SENDERADDRESS',
  },
  {
    id: 'SenderLocation',
    value: null,
    name: 'SenderLocation',
    required: true,
    type: 'text',
    placeholder: 'REQFORM.PLH.SENDERLOCATION',
    autocomplete: 'address-level2',
    validation: [
      {
        type: 'required',
        error: 'REQFORM.ERR.REQ.SENDERLOCATION',
      },
      {
        type: 'minlength',
        error: 'REQFORM.ERR.REGEXP.SENDERLOCATION',
      },
      {
        type: 'maxlength',
        error: 'REQFORM.ERR.REGEXP.SENDERLOCATION',
      },
    ],
    validators: {
      required: true,
      minlength: 3,
      maxlength: 100,
    },
    label: 'REQFORM.LBL.SENDERLOCATION',
  },
  {
    id: 'ReceiverCardHolderName',
    value: null,
    name: 'ReceiverCardHolderName',
    required: true,
    type: 'text',
    placeholder: 'REQFORM.PLH.RECEIVERCARDHOLDERNAME',
    autocomplete: 'cc-name',
    validation: [
      {
        type: 'required',
        error: 'REQFORM.ERR.REQ.RECEIVERCARDHOLDERNAME',
      },
      {
        type: 'minlength',
        error: 'REQFORM.ERR.REGEXP.RECEIVERCARDHOLDERNAME',
      },
      {
        type: 'maxlength',
        error: 'REQFORM.ERR.REGEXP.RECEIVERCARDHOLDERNAME',
      },
      {
        type: 'pattern',
        error: 'REQFORM.ERR.REGEXP.RECEIVERCARDHOLDERNAME',
      },
    ],
    validators: {
      required: true,
      minlength: 7,
      maxlength: 100,
      pattern: '[a-zA-Z]+(?:[-][a-zA-Z]+)* [a-zA-Z]+(?:[-][a-zA-Z]+)*$',
    },
    label: 'REQFORM.LBL.RECEIVERCARDHOLDERNAME',
  },
  {
    id: 'SenderCardNr',
    name: 'SenderCardNr',
    type: 'card-number',
    placeholder: 'P2P.SENDERBLOCK.PLH.CARDNR',
    validations: ['required', 'validCardNumber', 'validCardNumberLuhnCheck'],
    autoComplete: 'cc-number',
    validCardBrands: [
      {
        type: 'mastercard',
      },
    ],
    label: 'P2P.SENDERBLOCK.LBL.CARDNR',
  },
  {
    id: 'SenderCardCVV',
    name: 'SenderCardCVV',
    type: 'card-security-code',
    placeholder: 'P2P.SENDERBLOCK.PLH.CVV',
    validations: ['required', 'validCardSecurityCode'],
    autoComplete: 'cc-csc',
    label: 'P2P.SENDERBLOCK.LBL.CVV',
  },
  {
    id: 'SenderCardExpDate',
    name: 'SenderCardExpDate',
    type: 'card-expiration-date',
    placeholder: 'P2P.SENDERBLOCK.PLH.CARDEXP',
    validations: ['required', 'validCardExpirationDate'],
    yearLength: 2,
    autoComplete: 'cc-exp',
    label: 'P2P.SENDERBLOCK.LBL.CARDEXP',
  },
  {
    id: 'ReceiverCardNr',
    name: 'ReceiverCardNr',
    type: 'card-number',
    placeholder: 'P2P.RECEIVERBLOCK.PLH.CARDNR',
    validations: ['required', 'validCardNumber', 'validCardNumberLuhnCheck'],
    autoComplete: 'cc-number',
    validCardBrands: [
      {
        type: 'mastercard',
      },
    ],
    label: 'P2P.RECEIVERBLOCK.LBL.CARDNR',
  },
  {
    id: 'Amount',
    value: null,
    name: 'Amount',
    type: 'text',
    required: true,
    placeholder: 'REQFORM.PLH.AMOUNT',
    validation: [
      {
        type: 'required',
        error: 'REQFORM.ERR.REQ.AMOUNT',
      },
      {
        type: 'min',
        error: 'REQFORM.ERR.MIN.AMOUNT',
      },
      {
        type: 'max',
        error: 'REQFORM.ERR.MAX.AMOUNT',
      },
    ],
    validators: {
      required: true,
      minlength: 5,
      maxlength: 8,
      min: 10,
      max: 20000,
    },
    label: 'REQFORM.LBL.AMOUNT',
  },
  {
    id: 'Fee',
    name: 'Fee',
    type: 'text',
    required: true,
    readonly: true,
    placeholder: 'REQFORM.PLH.FEE',
    validation: [],
    label: 'REQFORM.LBL.FEE',
  },
  {
    id: 'Totals',
    name: 'Totals',
    type: 'text',
    required: true,
    readonly: true,
    placeholder: 'REQFORM.PLH.TOTALS',
    validation: [],
    label: 'REQFORM.LBL.TOTALS',
  },
];

const SenderSimpleFieldsArr = [
  'SenderCardHolderName',
  'SenderAddress',
  'SenderLocation',
];
const ReceiverSimpleFieldsArr = ['ReceiverCardHolderName'];
const AmountSimpleFieldsArr = ['Amount', 'Fee', 'Totals'];
const VGSFieldsArr = [
  'SenderCardNr',
  'SenderCardCVV',
  'SenderCardExpDate',
  'ReceiverCardNr',
];

@Injectable({ providedIn: 'root' })
export class P2PService {
  private P2P_KEY_TOKEN: string;

  constructor(
    private http: HttpClient,
    private translateService: TranslateService
  ) {}

  getFormFields(OplatamdObject) {
    return this.http
      .post<any>(`api/p2p/transfer/getform`, {
        externalDateTime: OplatamdObject.Data.RequestDate,
        ip: OplatamdObject.Data.IPAddress,
        userAgent: OplatamdObject.Data.UserAgent,
        externalReference: OplatamdObject.Data.ExternalId,
        signature: OplatamdObject.Signature,
        domainOrigin: OplatamdObject.Data.Origin,
      })
      .pipe(
        map((response) => {
          let fields = response.fields.map((x) => JSON.parse(x));
          /*MOCKK!!*/
          //fields = mockFields;
          /*MOCKK!!*/
          const DDCurrencies = response.currencies
            .map((x) => new Currency(x))
            .filter((x) => Currencies.map((y) => y.Code).includes(x.Code));

          const SenderSimpleFields = fields
            .filter((f) => SenderSimpleFieldsArr.includes(f.id))
            ?.map((x) => new FormField(x));

          const ReceiverSimpleFields = fields
            .filter((f) => ReceiverSimpleFieldsArr.includes(f.id))
            ?.map((x) => new FormField(x));

          const AmountSimpleFields = fields
            .filter((f) => AmountSimpleFieldsArr.includes(f.id))
            ?.map((x) => new FormField(x));

          const ArrCurrencyLimits: ICurrencyLimit[] = [];

          CurrencyLimits.forEach((x: ICurrencyLimit) => {
            const corelimit = response?.currencyLimits?.find(
              (y) => y.currencyId === x.currencyId
            );
            ArrCurrencyLimits.push(
              new CurrencyLimit(!!corelimit ? corelimit : x)
            );
          });

          // .filter((x) => !!x.currencyId)
          // .filter((x) =>
          //   CurrencyLimits.map((y) => y.currencyId).includes(x.currencyId)
          // );

          const VGSFields = fields.filter((f) => VGSFieldsArr.includes(f.id));

          this.P2P_KEY_TOKEN = response.tokenKey;

          console.log('SenderSimpleFields: ', SenderSimpleFields);
          console.log('ReceiverSimpleFields: ', ReceiverSimpleFields);
          console.log('AmountSimpleFields: ', AmountSimpleFields);
          console.log('VGSFields: ', VGSFields);

          return {
            SenderSimpleFields,
            ReceiverSimpleFields,
            AmountSimpleFields,
            VGSFields,
            DDCurrencies,
            ArrCurrencyLimits,
          };
        })
      );
  }

  recalculateAmount(IN: IRecalculateAmountIN) {
    const params = HttpClientUtils.toHttpParams(IN);
    return this.http
      .get<any>(`api/p2p/transfer/getcalculatedfee`, {
        headers: {
          p2p_token_key: this.P2P_KEY_TOKEN,
        },
        params,
      })
      .pipe(
        map((response: IRecalculateAmountOUT) => {
          return response;
        }),
        catchError((error) => {
          console.log('Error (transfer/getcalculatedfee): ', error.message);
          return throwError(() => error);
        })
      );
  }

  saveRequest(IN: ISaveRequestIN) {
    let data = [];
    for (let key in IN) {
      data.push({
        key: key,
        value: IN[key].toString(),
      });
    }
    return this.http
      .post<any>(
        `api/p2p/transfer/registerorder`,
        {
          data,
        },
        {
          headers: {
            p2p_token_key: this.P2P_KEY_TOKEN,
          },
        }
      )
      .pipe(
        map((response: ISaveRequestOUT) => {
          //console.log('registerorder response: ', response);
          return response;
        }),
        catchError((error) => {
          console.log('Error (transfer/registerorder): ', error.message);
          return throwError(() => error);
        })
      );
  }

  initPayment(IN: IInitPaymentIN) {
    IN = new InitPaymentIN(IN);

    return this.http
      .post<any>(`api/p2p/transfer/initpayment`, IN, {
        headers: {
          p2p_token_key: this.P2P_KEY_TOKEN,
        },
      })
      .pipe(
        map((response: IInitPaymentOUT) => {
          response.p2psessionKey = this.P2P_KEY_TOKEN;
          return response;
        }),
        catchError((error) => {
          console.log('Error (transfer/initpayment): ', error.message);
          return throwError(() => error);
        })
      );
  }

  getDetails(IN: IGetDetailsIN) {
    return this.http
      .post<any>(
        `api/p2p/transfer/getdetails`,
        { paymentKey: IN.paymentKey, checkoutKey: IN.checkoutKey },
        {
          headers: {
            p2p_token_key: IN.p2psessionKey,
          },
        }
      )
      .pipe(
        map((response: IGetDetailsOUT) => {
          return response;
        }),
        catchError((error) => {
          console.log('Error (transfer/getdetails): ', error.message);
          return throwError(() => error);
        })
      );
  }

  getDetailsPdf(IN: IGetDetailsIN): Observable<Blob> {
    return this.http
      .post(
        `api/p2p/transfer/getdetailspdf`,
        { paymentKey: IN.paymentKey, checkoutKey: IN.checkoutKey },
        {
          headers: {
            p2p_token_key: IN.p2psessionKey,
          },
        }
      )
      .pipe(
        switchMap((response: IGetDetailsPdfOUT) => {
          const mimetype = DetectMimeType(`${response.file.fileContents}`);
          const base64String = `data:${mimetype};base64,${response.file.fileContents}`;
          return this.toBlob(base64String);
        }),
        catchError((error) => {
          console.log('Error (transfer/getdetailspdf): ', error.message);
          return throwError(() => error);
        })
      );
  }

  private toBlob(file: string): Observable<Blob> {
    return from(
      fetch(file)
        .then((res) => res.blob())
        .then((blob) => blob)
    );
  }

  getPrintforOplata(IN: GetDetailsOUT): Observable<GetPrintOUT> {
    return new Observable((observer) => {
      const OUT: GetPrintOUT = {
        Fields: [
          {
            Key: 'ServiceIssuer',
            Name: this.translateService.instant(
              'RESULTPAGE.PRINT.SERVICEISSUER'
            ),
            Value: IN.ServiceIssuer,
          },
          {
            Key: 'OperationType',
            Name: this.translateService.instant(
              'RESULTPAGE.PRINT.OPERATIONTYPE'
            ),
            Value: IN.OperationType,
          },
          {
            Key: 'RES_Status_TRANSLATE',
            Name: this.translateService.instant('RESULTPAGE.PRINT.STATUS'),
            Value: this.translateService.instant(IN.RES_Status_TRANSLATE),
          },
          {
            Key: 'SenderName',
            Name: this.translateService.instant('RESULTPAGE.PRINT.SENDERNAME'),
            Value: IN.SenderName,
          },
          {
            Key: 'SenderCardNr',
            Name: this.translateService.instant(
              'RESULTPAGE.PRINT.SENDERCARDNR'
            ),
            Value: IN.SenderCardNr,
          },
          {
            Key: 'ReceiverName',
            Name: this.translateService.instant(
              'RESULTPAGE.PRINT.RECEIVERNAME'
            ),
            Value: IN.ReceiverName,
          },
          {
            Key: 'ReceiverCardNr',
            Name: this.translateService.instant(
              'RESULTPAGE.PRINT.RECEIVERCARDNR'
            ),
            Value: IN.ReceiverCardNr,
          },

          // {
          //   Key: 'RRN',
          //   Name: this.translateService.instant('RESULTPAGE.PRINT.RRN'),
          //   Value: IN.RRN,
          // },
          {
            Key: 'OperationID',
            Name: this.translateService.instant('RESULTPAGE.PRINT.OPERATIONID'),
            Value: IN.OperationID,
          },
          {
            Key: 'TransferAmount',
            Name: this.translateService.instant(
              'RESULTPAGE.PRINT.TRANSFERAMOUNT'
            ),
            Value: IN.TransferAmount,
          },
          {
            Key: 'Fee',
            Name: this.translateService.instant('RESULTPAGE.PRINT.TRANSFERFEE'),
            Value: IN.Fee,
          },
          {
            Key: 'Total',
            Name: this.translateService.instant(
              'RESULTPAGE.PRINT.TRANSFERTOTAL'
            ),
            Value: IN.Total,
          },
        ],
      };

      if ([Status.FAILED].includes(IN.Status)) {
        OUT.Fields.push({
          Key: 'ErrorCode',
          Name: this.translateService.instant('RESULTPAGE.PRINT.ERRORCODE'),
          Value: IN.ErrorCode,
        });
      }
      if ([Status.SUCCESS].includes(IN.Status)) {
        OUT.Fields.concat([
          {
            Key: 'CodeAuth',
            Name: this.translateService.instant('RESULTPAGE.PRINT.CODEAUTH'),
            Value: IN.CodeAuth,
          },
          {
            Key: 'TransferDate',
            Name: this.translateService.instant(
              'RESULTPAGE.PRINT.TRANSFERDATE'
            ),
            Value: IN.TransferDate,
          },
        ]);
      }
      observer.next(OUT);
      //observer.complete();
    });
  }

  getException(IN: IGetExceptionIN): Observable<GetExceptionOUT> {
    return new Observable((observer) => {
      const OUT: GetExceptionOUT = new GetExceptionOUT(IN);
      observer.next(OUT);
    });
  }
}
