import { CultureSharedService } from './../../_services/culture.shared.service';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { environment } from 'projects/p2p-plugin/src/environments/environment';
import { P2PService } from '../../_services/p2p.service';
import {
  GetDetailsOUT,
  IGetDetailsIN,
  Status,
} from '../../_models/p2p.service.model';
import { Langs, PM_ACTIONS } from '../../_models/common.model';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-result-page',
  templateUrl: './result-page.component.html',
})
export class ResultPageComponent {
  private oplatamd = window.parent as Window;
  private CurrentLang: string;
  private paymentKey: string;
  private checkoutKey: string;
  private p2psessionKey: string;
  public Model: GetDetailsOUT;
  public InProgress: boolean = true;
  private IN: IGetDetailsIN;
  public Timer: number;
  constructor(
    private translateService: TranslateService,
    private activatedRoute: ActivatedRoute,
    private p2pService: P2PService,
    private cultureSharedService: CultureSharedService
  ) { }

  ngOnInit() {
    this.parseParams();
  }
  private parseParams() {
    this.activatedRoute.params.subscribe((params) => {
      this.CurrentLang = params['lang'];
      this.paymentKey = params['paymentkey'];
      this.checkoutKey = params['checkoutkey'];
      this.p2psessionKey = params['p2psessionkey'];

      if (!!this.paymentKey && !!this.checkoutKey && !!this.p2psessionKey) {
        this.IN = {
          checkoutKey: this.checkoutKey,
          paymentKey: this.paymentKey,
          p2psessionKey: this.p2psessionKey,
          currentLang: this.CurrentLang
        };
        this.GetResult();
      }
    });

    this.translateService.use(
      Langs.includes(this.CurrentLang) ? this.CurrentLang : 'ru'
    );
    this.cultureSharedService.setLanguage(this.translateService.currentLang);
    //localStorage.setItem('currentLang', this.translateService.currentLang);
  }

  private GetResult() {
    console.log('GetResult');
    this.p2pService.getDetails(this.IN).subscribe({
      next: (response) => {
        this.Model = new GetDetailsOUT(response, this.CurrentLang);
        if (this.Model.Status === Status.INPROGRESS) {
          this.StartCountDown();
        }
        this.InProgress = false;
        setTimeout(() => this.P2PInProgress(false), 1000);
      },
      error: (error: HttpErrorResponse) => {
        console.log(error);
        this.P2PException(error);
      },
    });
  }

  ExpireTime: number = 5;
  private StartCountDown() {
    const interval = setInterval(() => {
      if (this.ExpireTime > 0) {
        this.ExpireTime--;
      } else {
        this.P2PInProgress(true);
        this.GetResult();
        this.ExpireTime = 5;
        clearInterval(interval);
      }
    }, 1000);
  }

  ExportPdf() {
    this.p2pService.getDetailsPdf(this.IN).subscribe({
      next: (response) => {
        //console.log('getfile', response);
        const url = window.URL.createObjectURL(response);
        const anchor = document.createElement('a');
        anchor.download = `${this.Model.OperationType} ${this.Model.OperationID}`;
        anchor.href = url;
        anchor.click();
        anchor.remove();
      },
      error: (error: HttpErrorResponse) => {
        console.log(error);
        this.P2PException(error);
      },
    });
  }

  Print() {
    this.p2pService.getPrintforOplata(this.Model).subscribe({
      next: (response) => {
        this.oplatamd.postMessage(
          {
            action: PM_ACTIONS.P2P_PRINT,
            object: response,
          },
          environment.oplatamdURL
        );
      },
    });
  }

  New() {
    this.oplatamd.postMessage(
      {
        action: PM_ACTIONS.P2P_NEW,
      },
      environment.oplatamdURL
    );
  }

  private P2PInProgress(state: boolean) {
    this.oplatamd.postMessage(
      {
        action: PM_ACTIONS.P2P_INPROGRESS,
        state: state,
      },
      environment.oplatamdURL
    );
  }

  private P2PException(error: HttpErrorResponse) {
    this.oplatamd.postMessage(
      {
        action: PM_ACTIONS.P2P_EXCEPTION,
        errorcode: error.status,
      },
      environment.oplatamdURL
    );
  }
}
