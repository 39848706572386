import { CultureSharedService } from './../../_services/culture.shared.service';
import { Component, HostListener } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  GetExceptionOUT,
  IGetExceptionIN,
} from '../../_models/p2p.service.model';
import { Langs, PM_ACTIONS } from '../../_models/common.model';
import { P2PService } from '../../_services/p2p.service';
import { environment } from 'projects/p2p-plugin/src/environments/environment';

@Component({
  selector: 'app-exception-page',
  templateUrl: './exception-page.component.html',
  styleUrls: ['./exception-page.component.scss'],
})
export class ExceptionPageComponent {
  private oplatamd = window.parent as Window;
  private CurrentLang: string;
  private exceptionCode: string;
  private IN: IGetExceptionIN;
  public Model: GetExceptionOUT;
  public InProgress: boolean = true;
  constructor(
    private translateService: TranslateService,
    private activatedRoute: ActivatedRoute,
    private p2pService: P2PService,
    private cultureSharedService: CultureSharedService
  ) { }

  ngOnInit() {
    this.parseParams();
  }

  @HostListener('window:message', ['$event'])
  onPostMessage(event: MessageEvent) {
    if (event.origin === environment.oplatamdURL && !!event.data) {
      switch (event.data.action) {
        case PM_ACTIONS.P2P_EXCEPTION_RESPONSE:
          {
            // console.log(event.data);
            this.IN = {
              exceptionCode: event.data.exceptioncode,
              exceptionMessage: event.data.exceptionmessage
            }
            this.GetException();
          }
          break;
        default:
          break;
      }
    }
  }

  private parseParams() {
    this.activatedRoute.params.subscribe((params) => {
      console.log(params);
      this.CurrentLang = params['lang'];
      // this.exceptionCode = params['exceptioncode'];

      // if (!!this.exceptionCode) {
      //   this.IN = {
      //     exceptionCode: this.exceptionCode,
      //   };
      //   this.GetException();
      // }
    });

    this.translateService.use(
      Langs.includes(this.CurrentLang) ? this.CurrentLang : 'ru'
    );
    this.cultureSharedService.setLanguage(this.CurrentLang)
    // localStorage.setItem('currentLang', this.translateService.currentLang);
  }

  private GetException() {
    this.p2pService.getException(this.IN).subscribe({
      next: (response) => {
        this.Model = response;
        this.InProgress = false;
        setTimeout(() => this.P2PInProgress(false), 1000);
      },
    });
  }

  private P2PInProgress(state: boolean) {
    //console.log('P2P-INPROGRESS', state);
    this.oplatamd.postMessage(
      {
        action: PM_ACTIONS.P2P_INPROGRESS,
        state: state,
      },
      environment.oplatamdURL
    );
  }

  New() {
    //console.log('P2P-INPROGRESS', state);
    this.oplatamd.postMessage(
      {
        action: PM_ACTIONS.P2P_NEW,
      },
      environment.oplatamdURL
    );
  }
}
