import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { environment } from '../environments/environment';

//The internationalization library for Angular.
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

//Components
import { AppComponent } from './app.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RequestFormComponent } from './components/request-form/request-form.component';
import { CallbackPageFROMComponent } from './components/callback-page-from/callback-page-from.component';
import { ResultPageComponent } from './components/result-page/result-page.component';
import { ExceptionPageComponent } from './components/exception-page/exception-page.component';

//reCAPTCHA
import {
  RECAPTCHA_V3_SITE_KEY,
  RECAPTCHA_LANGUAGE,
  RecaptchaV3Module,
} from 'ng-recaptcha';
import { JwtInterceptor } from './_helpers/jwt.interceptor';
import { PipesModule } from './_pipes/pipes.module';

//AOT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient): TranslateLoader {
  return new TranslateHttpLoader(http, `api/p2p/content/gettranslates/`, '');
}

@NgModule({
  declarations: [
    AppComponent,
    RequestFormComponent,
    CallbackPageFROMComponent,
    ResultPageComponent,
    ExceptionPageComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    FormsModule,
    ReactiveFormsModule,
    RecaptchaV3Module,
    PipesModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.RECAPTCHA_SITE_KEY,
    },
    {
      provide: RECAPTCHA_LANGUAGE,
      useFactory: (locale: string) => locale,
      deps: [LOCALE_ID],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
