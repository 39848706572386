import { HostListener, OnInit } from '@angular/core';
import { Component } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { P2PService } from '../../_services/p2p.service';
import {
  Currencies,
  FormField,
  ICurrency,
  ICurrencyLimit,
  IValidationType,
  SanitizedSRC,
} from '../../_models/request-form.model';
import { environment } from 'projects/p2p-plugin/src/environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import {
  AmountKeyPress,
  Langs,
  PM_ACTIONS,
  inIframe,
  AmountGetAsLong,
  AmountGetString,
  FormControlKeyPress,
  ERR_SERVER,
  IServerError,
  FormControlPaste,
} from '../../_models/common.model';
import { PM_FIELDS } from 'projects/psp-plugin/src/app/_models/common.model';
import {
  IInitPaymentIN,
  IInitPaymentOUT,
  IRecalculateAmountIN,
  ISaveRequestOUT,
  SaveRequest,
} from '../../_models/p2p.service.model';
import { HttpErrorResponse } from '@angular/common/http';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { CultureSharedService } from '../../_services/culture.shared.service';

@Component({
  selector: 'app-request-form',
  templateUrl: './request-form.component.html',
})
export class RequestFormComponent implements OnInit {
  private OplatamdObject;
  SRC: SanitizedSRC;
  private oplatamd = window.parent as Window;
  InProgress: boolean = true;
  Submitted: boolean = false;
  SenderFormFields: FormField[] = [];
  ReceiverFormFields: FormField[] = [];
  AmountFormFields: FormField[] = [];
  VFSFormFields: any[] = [];

  CurrenciesList: ICurrency[] = [];
  CurrencyLimitsList: ICurrencyLimit[] = [];

  private PSP_READY_CNT = 0;
  private PSP_TOKENIZED_CNT = 0;

  private ServerErrors: IServerError[] = [];

  GetServerError = (field: string): IServerError => {
    return this.ServerErrors.find((x) => x.field === field);
  };

  private RemoveServerError = (field: string): void => {
    this.ServerErrors = this.ServerErrors.filter((x) => x.field !== field);
  };

  private SetServerError = (ServerError: IServerError): void => {
    this.ServerErrors.push(ServerError);
  };

  RequestForm: FormGroup = this.formBuilder.group({
    SenderCVV: new FormControl(false, [Validators.requiredTrue]),
    SenderCardExp: new FormControl(false, [Validators.requiredTrue]),
    RequestConfirmation: new FormControl(false, Validators.requiredTrue),

    //Sender Block iframe inputs:
    SenderCardType: new FormControl('', Validators.required),
    SenderCardBin: new FormControl('', Validators.required),
    SenderCardLast4: new FormControl('', Validators.required),
    SenderCardBinChecked: new FormControl('', Validators.requiredTrue),

    //Receiver CardNr iframe inputs:
    ReceiverCardType: new FormControl('', Validators.required),
    ReceiverCardBin: new FormControl('', Validators.required),
    ReceiverCardLast4: new FormControl('', Validators.required),
    ReceiverCardBinChecked: new FormControl('', Validators.requiredTrue),

    CurrencyName: new FormControl('MDL'),
    CurrencyID: new FormControl(498, [Validators.required]),
  });

  CurrencyNameControl: FormControl = <FormControl>(
    this.RequestForm.get('CurrencyName')
  );
  CurrencyIDControl: FormControl = <FormControl>(
    this.RequestForm.get('CurrencyID')
  );
  SenderCardBinControl: FormControl = <FormControl>(
    this.RequestForm.get('SenderCardBin')
  );
  ReceiverCardBinControl: FormControl = <FormControl>(
    this.RequestForm.get('ReceiverCardBin')
  );

  AmountControl: FormControl;
  FeeControl: FormControl;
  TotalsControl: FormControl;

  constructor(
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private p2pService: P2PService,
    private sanitizer: DomSanitizer,
    private recaptchaV3Service: ReCaptchaV3Service,
    private cultureSharedService: CultureSharedService
  ) {
    this.SenderCardBinControl.valueChanges.subscribe(() => {
      if (
        this.BinsStateAfterRecalc != this.BinsStateCurrent ||
        !this.Recalculated
      ) {
        this.Recalculate();
      }
    });
    this.ReceiverCardBinControl.valueChanges.subscribe(() => {
      if (
        this.BinsStateAfterRecalc != this.BinsStateCurrent ||
        !this.Recalculated
      ) {
        this.Recalculate();
      }
    });
  }

  ngOnInit() { }

  private Start() {
    if (inIframe()) {
      this.translateService.use(
        Langs.includes(this.OplatamdObject.Language)
          ? this.OplatamdObject.Language
          : 'ru'
      );
    } else {
      this.translateService.use('ru');
    }
    this.cultureSharedService.setLanguage(this.translateService.currentLang);
    // localStorage.setItem('currentLang', this.translateService.currentLang);
    this.translateService.onLangChange.subscribe(
      (event: LangChangeEvent) => { }
    );

    this.InitForm(this.OplatamdObject);
  }

  private InitForm(OplatamdObject) {
    this.p2pService.getFormFields(OplatamdObject).subscribe({
      next: ({
        SenderSimpleFields,
        ReceiverSimpleFields,
        AmountSimpleFields,
        VGSFields,
        DDCurrencies,
        ArrCurrencyLimits,
      }) => {
        if (!!SenderSimpleFields) {
          this.SenderFormFields = SenderSimpleFields;
        }
        if (!!ReceiverSimpleFields) {
          this.ReceiverFormFields = ReceiverSimpleFields;
        }
        if (!!AmountSimpleFields) {
          this.AmountFormFields = AmountSimpleFields;
        }
        if (!!VGSFields) {
          this.VFSFormFields = VGSFields;
        }

        this.CurrenciesList = [...DDCurrencies];
        this.CurrencyLimitsList = [...ArrCurrencyLimits];

        this.SenderFormFields.filter((x) => !!x.FormControl).forEach((x) => {
          this.RequestForm.addControl(x.ID, x.FormControl);
        });
        this.ReceiverFormFields.filter((x) => !!x.FormControl).forEach((x) => {
          this.RequestForm.addControl(x.ID, x.FormControl);
        });
        this.AmountFormFields.filter((x) => !!x.FormControl).forEach((x) => {
          this.RequestForm.addControl(x.ID, x.FormControl);
        });

        console.log('this.RequestForm: ', this.RequestForm);
        console.log('this.CurrencyLimitsList: ', this.CurrencyLimitsList);

        this.AmountControl = <FormControl>this.RequestForm.get('Amount');
        this.FeeControl = <FormControl>this.RequestForm.get('Fee');
        this.TotalsControl = <FormControl>this.RequestForm.get('Totals');
        this.SetCurrencyLimits(this.CurrencyIDControl.value);

        this.InProgress = !(
          !!SenderSimpleFields &&
          !!ReceiverSimpleFields &&
          !!AmountSimpleFields &&
          !!VGSFields
        );
      },
      error: (error: HttpErrorResponse) => {
        console.log(error);
        //this.P2PInProgress(true);
        this.P2PException(error);
      },
      complete: () => {
        // console.log('getFormFields request completed');
      },
    });
  }

  @HostListener('window:message', ['$event'])
  onPostMessage(event: MessageEvent) {
    if (event.origin === environment.oplatamdURL) {
      if (!!event.data) {
        switch (event.data.action) {
          case PM_ACTIONS.P2P_START_IN:
            console.log(event.data);
            //console.log('OplatamdObject', event.data.OplatamdObject);
            this.OplatamdObject = event.data.OplatamdObject;
            this.SRC = new SanitizedSRC(
              this.OplatamdObject.Language,
              this.sanitizer
            );
            this.Start();
            break;
          case PM_ACTIONS.P2P_RECAPTCHA_RESPONSE:
            console.log(event.data);

            if (!!event.data?.OplatamdObject?.Success) {
              this.RemoveServerError('Recaptcha');
              this.SaveRequest(this.RequestForm.value);
            }
            // Recaptcha Validate Fail
            else {
              this.SetServerError({
                field: 'Recaptcha',
                error: ERR_SERVER.RECAPTCHA_EXCEPTION,
              });

              this.P2PInProgress(false);
              this.PSPValidate();
            }
            break;
          default:
            break;
        }
      }
    }

    if (event.origin === environment.pspURL) {
      if (!!event.data) {
        switch (event.data.action) {
          case PM_ACTIONS.PSP_VGS_LOADED:
            console.log(event.data);
            this.PSPInitFields(event.data);
            break;

          case PM_ACTIONS.PSP_READY:
            console.log(event.data);
            this.PSP_READY_CNT++;
            if (this.PSP_READY_CNT === 2) {
              console.log(`LISTEN: PSP_READY_CNT ${PM_ACTIONS.PSP_READY}`)
              this.P2PInProgress(false);
            }
            break;
          case PM_ACTIONS.PSP_VALIDATE_OUT:
            this.PatchForm(event.data);
            break;
          case PM_ACTIONS.PSP_TOKENIZED:
            console.log(event.data);
            this.PSP_TOKENIZED_CNT++;
            if (this.PSP_TOKENIZED_CNT === 2) {
              this.InitPayment();
            }
            break;
          case PM_ACTIONS.PSP_PREPARE_3DS:
            console.log(event.data);
            if (event.data.object) {
              this.FinishPaymentAfter3DS(event.data.object);
            }
            break;
          case PM_ACTIONS.PSP_EXCEPTION:
            console.log(event.data);
            this.P2PException(event.data.error);
            break;
          default:
            return;
        }
      }
    }
  }

  @HostListener('window:unload', ['$event'])
  onUnloadTo3DS() {
    window.parent.postMessage(
      {
        action: PM_ACTIONS.P2P_WAITING_3DS,
      },
      environment.oplatamdURL
    );
  }

  private PSPInitFields(data) {
    const iframe = document.getElementById(data.iframe) as HTMLIFrameElement;
    iframe.contentWindow.postMessage(
      {
        action: PM_ACTIONS.PSP_INIT_FIELDS,
        fields: this.VFSFormFields.filter((f) => data.fields.includes(f.id)),
      },
      environment.pspURL
    );
  }

  private PSPValidate() {
    this.Submitted = true;
    const iframes = Array.from([
      document.getElementsByTagName('iframe').namedItem('PSPSenderBlock'),
      document.getElementsByTagName('iframe').namedItem('PSPReceiverBlock'),
    ]);

    iframes.forEach((iframe) => {
      iframe.contentWindow.postMessage(
        {
          action: PM_ACTIONS.PSP_VALIDATE_IN,
        },
        environment.pspURL
      );
    });
  }

  private PSPConfirm() {
    const iframe = document.getElementById(
      'PSPCallBackPageTO'
    ) as HTMLIFrameElement;
    iframe.contentWindow.postMessage(
      {
        action: PM_ACTIONS.PSP_CONFIRM,
        object: this.InitPaymentOUTObject,
      },
      environment.pspURL
    );
  }

  //private PSPSubmitOUT: IPSPSubmitOUT;
  private PSPSubmit(tokenboxes: ISaveRequestOUT) {
    this.PSP_TOKENIZED_CNT = 0;
    this.SaveRequestOUTObject = tokenboxes;
    const iframes = Array.from([
      document.getElementsByTagName('iframe').namedItem('PSPSenderBlock'),
      document.getElementsByTagName('iframe').namedItem('PSPReceiverBlock'),
    ]);

    iframes.forEach((iframe) => {
      iframe.contentWindow.postMessage(
        {
          action: PM_ACTIONS.PSP_SUBMIT,
          tokenboxes: tokenboxes.associatedTokenBoxKeys,
        },
        environment.pspURL
      );
    });
  }

  private PatchForm(pspObj: any) {
    switch (pspObj.field) {
      case PM_FIELDS.PSP_SENDER_CARDNR:
        this.RequestForm.patchValue({
          SenderCardType: pspObj.data.cardType,
          SenderCardBin: pspObj.data.bin?.slice(0, 6),
          SenderCardLast4: pspObj.data.last4,
        });
        break;
      case PM_FIELDS.PSP_SENDER_CARDCVV:
        this.RequestForm.patchValue({
          SenderCVV: pspObj.data.isValid,
        });
        break;
      case PM_FIELDS.PSP_SENDER_CARDEXP:
        this.RequestForm.patchValue({
          SenderCardExp: pspObj.data.isValid,
        });
        break;
      case PM_FIELDS.PSP_SENDER_CARDBINCHECK:
        this.RequestForm.patchValue({
          SenderCardBinChecked: pspObj.checkedbin,
        });
        console.log(this.RequestForm.value);
        break;
      case PM_FIELDS.PSP_RECEIVER_CARDBINCHECK:
        this.RequestForm.patchValue({
          ReceiverCardBinChecked: pspObj.checkedbin,
        });
        console.log(this.RequestForm.value);
        break;
      case PM_FIELDS.PSP_RECEIVER_CARDNR:
        this.RequestForm.patchValue({
          ReceiverCardType: pspObj.data.cardType,
          ReceiverCardBin: pspObj.data.bin?.slice(0, 6),
          ReceiverCardLast4: pspObj.data.last4,
        });
        break;
      default:
        break;
    }
    //console.log(this.RequestForm.value);
  }

  private Validate() {
    this.P2PInProgress(true);
    if (this.RequestForm.valid) {
      this.recaptchaV3Service.execute('GetToken').subscribe((token: string) => {
        console.log('recaptcha token: ', token);
        this.oplatamd.postMessage(
          {
            action: PM_ACTIONS.P2P_RECAPTCHA_VERIFY,
            response: token,
            remoteip: this.OplatamdObject.IPAddress,
          },
          environment.oplatamdURL
        );
      });
    } else {
      this.P2PInProgress(false);
      this.PSPValidate();
    }
  }

  private SaveRequestOUTObject: ISaveRequestOUT;
  private SaveRequest(formData) {
    const IN: SaveRequest = new SaveRequest(formData);

    this.p2pService.saveRequest(IN).subscribe({
      next: (response) => {
        console.log(response);
        this.PSPSubmit(response);
      },
      error: (error: HttpErrorResponse) => {
        console.log(error);
        this.P2PException(error);
        //this.P2PInProgress(false);
      },
    });
  }

  private InitPaymentOUTObject: IInitPaymentOUT;
  private InitPayment() {
    const IN: IInitPaymentIN = {
      transferOrderKey: this.SaveRequestOUTObject.transferOrderKey,
    };
    this.p2pService.initPayment(IN).subscribe({
      next: (response: IInitPaymentOUT) => {
        //console.log(response);
        this.InitPaymentOUTObject = response;
        this.PSPConfirm();
      },
      error: (error: HttpErrorResponse) => {
        console.log(error);
        this.P2PException(error);
        //this.P2PInProgress(false);
      },
    });
  }

  private P2PInProgress(state: boolean) {
    //console.log('P2P-INPROGRESS', state);
    this.oplatamd.postMessage(
      {
        action: PM_ACTIONS.P2P_INPROGRESS,
        state: state,
      },
      environment.oplatamdURL
    );
  }
  private P2PException(error: HttpErrorResponse) {
    this.oplatamd.postMessage(
      {
        action: PM_ACTIONS.P2P_EXCEPTION,
        errorcode: error.status,
        errormessage:
          error?.error?.errorInfo?.title ??
          error?.error?.errorInfo?.ppResponseCodeDescription ??
          error?.error?.errorInfo?.ppResponseCodeText ??
          error?.message,
      },
      environment.oplatamdURL
    );
  }

  private FinishPaymentAfter3DS(OUT) {
    const node3DSForm = new DOMParser().parseFromString(
      OUT.htmlForm3DS,
      'text/html'
    );
    this.oplatamd.postMessage(
      {
        action: PM_ACTIONS.P2P_WAITING_3DS,
      },
      environment.oplatamdURL
    );
    //this.P2PInProgress(false);
    document
      .getElementsByTagName('body')[0]
      .appendChild(node3DSForm.querySelector('form'));
    (
      document.getElementsByName('ThreeDform').item(0) as HTMLFormElement
    ).submit();
  }

  onSubmit() {
    this.Submitted = true;
    console.log('this form: ', this.RequestForm);

    this.Validate();
    console.log('this.RequestForm: ', this.RequestForm);
  }

  ChangeCurrency(currency: number) {
    this.RequestForm.patchValue({
      CurrencyID: Currencies.find((x) => x.Num === currency).Num,
    });
    this.RequestForm.patchValue({
      CurrencyName: Currencies.find((x) => x.Num === currency).Code,
    });
    this.SetCurrencyLimits(currency);
    this.Recalculate();
  }

  private SetCurrencyLimits(currencyId: number) {
    const currentLimit = this.CurrencyLimitsList.find(
      (x) => x.currencyId === currencyId
    );
    const validators = [
      ...(currentLimit.min
        ? [
          Validators.min(Number(AmountGetString(currentLimit.min))),
          Validators.minLength(AmountGetString(currentLimit.min).length),
        ]
        : []),
      ...(currentLimit.max
        ? [
          Validators.max(Number(AmountGetString(currentLimit.max))),
          Validators.maxLength(AmountGetString(currentLimit.max).length),
        ]
        : []),
    ];
    this.AmountControl.setValidators(validators);
    this.AmountControl.updateValueAndValidity();

    const field = this.AmountFormFields.find((x) => x.ID === 'Amount');
    const validationMin = field.Validation.find(
      (x) => x.Type === IValidationType.min
    );
    validationMin.Error =
      'REQFORM.ERR.MIN.AMOUNT.' +
      Currencies.find((x) => x.Num === currencyId).Code;
    const validationMax = field.Validation.find(
      (x) => x.Type === IValidationType.max
    );
    validationMax.Error =
      'REQFORM.ERR.MAX.AMOUNT.' +
      Currencies.find((x) => x.Num === currencyId).Code;
    field.MaxLength = AmountGetString(currentLimit.max).length;

    // console.log(this.AmountControl.errors);
  }

  private BinsStateAfterRecalc: string;
  private get BinsStateCurrent(): string {
    return this.SenderCardBinControl.value + this.ReceiverCardBinControl.value;
  }

  private get Recalculated(): boolean {
    return !!this.TotalsControl.value && !!this.FeeControl.value;
  }
  private Recalculate() {
    const val = Number(this.AmountControl.value?.replaceAll(',', '.'));
    const patchvalue = isNaN(val) ? null : val.toFixed(2);
    this.AmountControl.patchValue(patchvalue);
    if (
      this.AmountControl.valid &&
      this.SenderCardBinControl.valid &&
      this.ReceiverCardBinControl.valid
    ) {
      this.P2PInProgress(true);
      const IN: IRecalculateAmountIN = {
        amount: AmountGetAsLong(this.AmountControl.value),
        senderCardBin: this.SenderCardBinControl.value,
        receiverCardBin: this.ReceiverCardBinControl.value,
        currencyId: this.CurrencyIDControl.value,
      };

      this.p2pService.recalculateAmount(IN).subscribe({
        next: (response) => {
          console.log(response);
          this.FeeControl.patchValue(AmountGetString(response.comission));
          this.TotalsControl.patchValue(AmountGetString(response.amountTotal));
          this.BinsStateAfterRecalc = this.BinsStateCurrent;
          this.RemoveServerError('Fee');
          this.P2PInProgress(false);
        },
        error: (error: HttpErrorResponse) => {
          console.log(error);
          console.log(error.headers.get('Content-Type'));
          if (error.status === 500) {
            if (error.headers.get('Content-Type').startsWith('text/plain')) {
              // unexpected server exception
              this.SetServerError({
                field: 'Fee',
                error: ERR_SERVER.FEE_EXCEPTION,
              });
            }
          }
          this.FeeControl.patchValue(null);
          this.TotalsControl.patchValue(null);
          this.P2PInProgress(false);
        },
      });
    } else {
      this.FeeControl.patchValue(null);
      this.TotalsControl.patchValue(null);
      this.AmountControl.patchValue(patchvalue);
    }
  }

  FormControlKeyPress(event) {
    let pattern;
    switch (event.target.id) {
      case 'SenderCardHolderName':
      case 'ReceiverCardHolderName':
        pattern = /^[A-Za-z\s\-]+$/;
        break;
      case 'SenderAddress':
        pattern = /^[0-9A-Za-z\s\-\,\.\/]+$/;
        break;
      case 'SenderLocation':
        pattern = /^[A-Za-z\s\-]+$/;
        break;
    }
    FormControlKeyPress(event, pattern);
  }

  FormControlPaste(event) {
    let pattern;
    switch (event.target.id) {
      case 'SenderCardHolderName':
      case 'ReceiverCardHolderName':
        pattern = /^[A-Za-z\s\-]+$/;
        break;
      case 'SenderAddress':
        pattern = /^[0-9A-Za-z\s\-\,\.\/]+$/;
        break;
      case 'SenderLocation':
        pattern = /^[A-Za-z\s\-]+$/;
        break;
    }
    FormControlPaste(event, pattern);
  }

  AlphaFocusOut(event, ctrl: FormControl) {
    const val = event.target.value
      .split(' ')
      .filter((x) => x !== '')
      .join(' ');

    ctrl.patchValue(
      ['SenderCardHolderName', 'ReceiverCardHolderName'].includes(
        event.target.id
      )
        ? val.toUpperCase()
        : val
    );
  }

  AmountKeyPress(event) {
    AmountKeyPress(event);
  }

  private AmountControlFocusInValue: number;

  AmountFocusIn(event) {
    if (Number(event.target.value) === 0) {
      event.target.value = '';
    }
    this.AmountControlFocusInValue = Number(event.target.value);
  }
  AmountFocusOut() {
    if (this.AmountControlFocusInValue !== Number(this.AmountControl.value)) {
      this.Recalculate();
    } else {
      this.AmountControl.patchValue(this.AmountControlFocusInValue.toFixed(2));
    }
  }
}
