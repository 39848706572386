import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../environments/environment';

@Pipe({
  name: 'imgsrc',
  pure: true,
})
export class ImgSrcPipe implements PipeTransform {
  constructor() {}
  transform(code: string): string {
    // console.log('imgapi: ', code);
    return `${environment.apiUrl}/api/p2p/content/getimage/${code}`;
  }
}
