import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'projects/p2p-plugin/src/environments/environment';

@Component({
  selector: 'app-callback-page',
  templateUrl: './callback-page-from.component.html',
})
export class CallbackPageFROMComponent {
  private paymentKey: string;
  private checkoutKey: string;
  private p2psessionKey: string;
  constructor(
    private activatedRoute: ActivatedRoute //private pspService: P2PService
  ) {
    this.parseParams();
  }

  private parseParams() {
    this.activatedRoute.params.subscribe((params) => {
      console.log(params);
      this.paymentKey = params['paymentkey'];
      this.checkoutKey = params['checkoutkey'];
      this.p2psessionKey = params['p2psessionkey'];

      if (!!this.paymentKey && !!this.checkoutKey) {
        this.RedirectToOplata();
      }
    });
  }
  private RedirectToOplata() {
    window.parent.location.href = `${environment.oplatamdURL}/p2p/result/${this.paymentKey}/${this.checkoutKey}/${this.p2psessionKey}`;
  }
}
