import { Pipe, PipeTransform } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Observable, map } from 'rxjs';

@Pipe({
  name: 'imgapi',
})
export class ImgApiPipe implements PipeTransform {
  constructor(private http: HttpClient, private sanitizer: DomSanitizer) {}
  transform(code: string): Observable<SafeUrl> {
    // console.log('imgapi: ', code);
    return this.http
      .get(`api/p2p/content/getimage/${code}`, {
        responseType: 'blob',
        observe: 'response',
      })
      .pipe(
        map((response) =>
          this.sanitizer.bypassSecurityTrustUrl(
            URL.createObjectURL(response.body)
          )
        )
      );
  }
}
